<template>
  <top-header-menu-wrapper>
    <template v-slot:left>
      <home-button />
    </template>
    <div class="component-title">
      Two-Factor Authentication
    </div>
  </top-header-menu-wrapper>
</template>

<script>
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";

export default {
  name: "TwoFactorAuthenticationHeaderNav",
  components: {
    TopHeaderMenuWrapper
  }
};
</script>
