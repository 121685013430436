<template>
  <mobile-screen :header="true" screen-class="profile-screen icon-app1 gray-bg">
    <template v-slot:header>
      <two-factor-authentication-header-nav></two-factor-authentication-header-nav>
    </template>
    <div class="p-10">
      <div v-if="subbmited">
        <p>
          <label>
            Two-factor authentication is now enabled. Scan the following QR code
            using your phone authenticator application.
          </label>
        </p>
        <img
          class="pt-10"
          v-if="authObject && authObject.image"
          :src="authObject.image"
        />
        <p class="pt-10">
          <label class="text"
            >If you are unable to scan the image, enter the following
            information in your app.</label
          >
        </p>
        <p class="pt-10">Code: {{ authObject ? authObject.secret : null }}</p>
        <div class="pt-50">
          <span class="parent">
            <Field
              name="code"
              v-model="code"
              as="input"
              rules="required"
              type="text"
              class="child"
              placeholder="Enter the code..."
              :maxlength="6"
            />
            <button
              class="btn primary child btn-w100"
              @click="
                verify2fa({ secret: authObject ? authObject.secret : null })
              "
            >
              Verify
            </button>
          </span>
        </div>
        <label v-if="message">{{ this.message }}</label>
      </div>
      <div v-else>
        <div v-if="isEnabled != null">
          <div v-if="isEnabled">
            <p>
              <label> Two-factor authentication is enabled. </label>
            </p>
            <p class="pt-10 ">
              <button class="body-btn" @click="show = true">
                Disable two-factor authentication
              </button>
            </p>
          </div>
          <div v-else>
            <p>
              <label>
                Add additinal security to your account using two factor
                authentication.
              </label>
            </p>
            <p class="pt-10 ">
              <button
                class="body-btn btn primary clebex-button-text"
                @click="enable2fa"
              >
                Enable two-factor authentication
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>

    <screen-modal
      v-if="show"
      class="confirm-modal"
      type="success"
      :hide-timer="true"
      :confirm-action="disable2fa"
      :confirm-button-label="displayLabelName('global', 'mobile-screen', 'ok')"
      :cancel-button-label="
        displayLabelName('global', 'mobile-screen', 'cancel')
      "
      :show="show"
      @close="show = false"
    >
      <h3 class="modal-title">Password</h3>
      <p class="text pt-10">
        <Field
          name="password"
          v-model="password"
          as="input"
          rules="required"
          type="password"
          placeholder="Please enter password"
        />
      </p>
    </screen-modal>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TwoFactorAuthenticationHeaderNav from "@/components/authentication/TwoFactorAuthenticationHeaderNav";
import httpServiceAuth from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";

export default {
  name: "TwoFactorAuthentications",
  data() {
    return {
      show: false,
      isEnabled: null,
      subbmited: false,
      password: null,
      authObject: null,
      message: null,
      code: null
    };
  },
  beforeMount() {
    this.checkStatus();
  },
  methods: {
    checkStatus: function() {
      let url = `${apiEndpoints.master.auth2fa}/check`;
      httpServiceAuth
        .get(url)
        .then(response => {
          this.isEnabled = response.data;
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response, this.findElement());
          }
        });
    },
    enable2fa: function() {
      let url = `${apiEndpoints.master.auth2fa}/enable`;
      this.$store.commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .get(url)
        .then(response => {
          this.authObject = response.data;
          this.subbmited = true;
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response, this.findElement());
          }
        })
        .finally(() => {
          this.$store.commit("loader/setScreenLoading", false, {
            root: true
          });
        });
    },
    disable2fa: function() {
      let url = `${apiEndpoints.master.auth2fa}/disable`;
      const data = {
        password: this.password
      };
      if (data.password) {
        httpServiceAuth
          .post(url, data)
          .then(() => {
            this.isEnabled = false;
          })
          .catch(error => {
            if (error.response) {
              errorHandler(error.response, this.findElement());
            }
          });
      }
    },
    verify2fa: function(data) {
      data.code = this.code;
      if (data.code) {
        let url = `${apiEndpoints.master.auth2fa}/verify`;
        this.$store.commit("loader/setScreenLoading", true, { root: true });
        httpServiceAuth
          .post(url, data)
          .then(response => {
            if (response.data.verified == true) {
              window.location.reload();
            } else {
              this.message = "Your entered OTP code is incorrect";
            }
          })
          .catch(error => {
            if (error.response) {
              errorHandler(error.response, this.findElement());
            }
          })
          .finally(() => {
            this.$store.commit("loader/setScreenLoading", false, {
              root: true
            });
          });
      }
    }
  },
  components: {
    MobileScreen,
    TwoFactorAuthenticationHeaderNav
  }
};
</script>
<style lang="scss" scoped>
.p-10 {
  padding: 10px;
}
.pt-10 {
  padding-top: 10px;
}
.pt-50 {
  padding-top: 50px;
}
.btn-w100 {
  width: 110px;
}
.parent {
  white-space: nowrap;
}
.child {
  display: inline-block;
}
.clebex-button-text {
  font-size: 14px !important;
}
</style>
